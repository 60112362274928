<template>
  <a-space>
    <span>{{ title }}</span>
    <a-tooltip :title="description">
      <a-icon
        type="question-circle"
        style="color: rgba(0, 0, 0, 0.45)"
      ></a-icon>
    </a-tooltip>
  </a-space>
</template>

<script>
export default {
  name: 'QuestionIcon',
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  }
}
</script>
