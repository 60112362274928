import qs from 'qs'
import request from '@/utils/request'

const urlPrefix = '/alerts'

export function getAlert (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function updateAlert (id, data) {
  return request.put(`${urlPrefix}/${id}`, data)
}

export function deleteAlert (id) {
  return request.delete(`${urlPrefix}/${id}`)
}

export function getAlertSnapshotList (id) {
  return request.get(`${urlPrefix}/${id}/snapshots`)
}

export function getAlertNotificationList (id) {
  return request.get(`${urlPrefix}/${id}/notifications`)
}

export function getAlertList (params) {
  return request.get(urlPrefix, { params })
}

export function getAlertCount (params) {
  return request.get(`${urlPrefix}/count`, { params })
}

export function getAlertTop (params) {
  return request.get(`${urlPrefix}/top`, { params })
}

export function getAlertTrend (params) {
  return request.get(`${urlPrefix}/trend`, { params })
}

export function exportAlert (params) {
  return request.get(`${urlPrefix}/export`, {
    params,
    paramsSerializer: obj => {
      return qs.stringify(obj, { arrayFormat: 'repeat' })
    },
    responseType: 'blob'
  })
}
export function getAlertAutomationStrategy (id) {
  return request.get(`${urlPrefix}/automation-strategies/${id}`)
}

export function getAlertAutomationStrategyList (params) {
  return request.get(`${urlPrefix}/automation-strategies`, { params })
}

export function createAlertAutomationStrategy (data) {
  return request.post(`${urlPrefix}/automation-strategies`, data)
}

export function deleteAutomationStrategy (id) {
  return request.delete(`${urlPrefix}/automation-strategies/${id}`)
}

export function updateAutomationStrategy (id, data) {
  return request.put(`${urlPrefix}/automation-strategies/${id}`, data)
}
