<template>
  <a-select
    :allow-clear="false"
    :disabled="disabled"
    :filter-option="false"
    :label-in-value="true"
    :mode="mode"
    :options="options"
    :show-search="true"
    :value="selected"
    @change="change"
    @popupScroll="popupScroll"
    @search="search"
  ></a-select>
</template>

<script>
import { getScriptList } from '@/api/script.js'

export default {
  name: 'ScriptList',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'default'
    },
    value: {
      type: [Array, Object]
    }
  },
  data () {
    return {
      form: {
        page: 1,
        pageSize: 10
      },
      selected: undefined,
      options: [],
      total: 0,
      timer: null
    }
  },
  mounted () {
    this.selected = this.value ? this.formatValue(this.value) : undefined
    this.fetch()
  },
  methods: {
    fetch () {
      const params = {
        page: this.form.page,
        page_size: this.form.pageSize
      }
      if (this.form.name) params.name = this.form.name
      getScriptList(params).then(res => {
        const data = res.data
        this.total = data.total
        data.data.forEach(item => {
          if (
            this.options.filter(option => option.value === item.id)
              .length === 0
          ) {
            this.options.push({
              key: item.id,
              label: item.name
            })
          }
        })
      })
    },
    formatValue (value) {
      if (this.mode === 'multiple') {
        return value.map(item => {
          return {
            key: item.id,
            label: item.name
          }
        })
      } else {
        return {
          key: value.id,
          label: value.name
        }
      }
    },
    change (value) {
      if (this.mode === 'multiple') {
        this.$emit(
          'input',
          value.map(item => {
            return {
              id: item.key,
              name: item.label
            }
          })
        )
      } else {
        this.$emit('input', {
          id: value.key,
          name: value.label
        })
      }
    },
    popupScroll (e) {
      const { scrollHeight, scrollTop, clientHeight } = e.target
      if (scrollHeight - scrollTop <= clientHeight) {
        if (this.total > this.form.page * this.form.pageSize) {
          this.form.page += 1
          this.fetch()
        }
      }
    },
    search (value) {
      this.form.name = value
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.options = []
        this.fetch()
      }, 500)
    }
  },
  watch: {
    value: {
      handler (v) {
        this.selected = v ? this.formatValue(v) : undefined
      },
      deep: true
    }
  }
}
</script>
