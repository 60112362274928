<template>
  <a-popconfirm
    ok-type="danger"
    title="是否确定删除？"
    @confirm="confirm"
    style="color: #f5222d"
  >
    <a-icon slot="icon" type="question-circle-o"></a-icon>
    <a-button icon="delete" size="small" type="link">删除</a-button>
  </a-popconfirm>
</template>

<script>
export default {
  name: 'DeleteButton',
  methods: {
    confirm (e) {
      this.$emit('confirm', e)
    }
  }
}
</script>
