<template>
  <a-drawer
    :destroy-on-close="true"
    :title="`自动化策略${isUpdate ? '编辑' : '创建'}`"
    :visible="visible"
    :width="800"
    @close="closeDrawer"
  >
    <a-form-model
      ref="form"
      :label-col="{
        span: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 20
      }"
    >
      <a-form-model-item prop="name" label="名称">
        <a-input v-model="form.name" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item prop="pattern">
        <question-icon
          slot="label"
          title="匹配模式"
          description="支持正则匹配"
        ></question-icon>
        <a-input v-model="form.pattern" :max-length="128"></a-input>
      </a-form-model-item>
      <a-form-model-item label="选择脚本" prop="script">
        <script-select :value="form.script" @input="handleChange"></script-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="active">
        <a-switch v-model="form.active"></a-switch>
      </a-form-model-item>
      <a-form-model-item
        :wrapper-col="{ span: 20, offset: 4 }"
        style="margin-bottom: 0"
      >
        <a-space size="middle">
          <a-button :loading="loading" type="primary" @click="ok">
            确定
          </a-button>
          <a-button @click="closeDrawer">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { createAlertAutomationStrategy, getAlertAutomationStrategy, updateAutomationStrategy } from '@/api/alert'
import ScriptSelect from '@/components/select/ScriptSelect'
import QuestionIcon from '@/components/icon/QuestionIcon'

export default {
  name: 'InputDrawer',
  components: {
    ScriptSelect,
    QuestionIcon
  },
  data () {
    return {
      visible: false,
      loading: false,
      isUpdate: false,
      form: {
        id: '',
        name: '',
        active: false,
        pattern: '',
        script: undefined,
        scriptId: ''
      },
      rules: {
        pattern: {
          message: '请输入匹配模式',
          required: true,
          trigger: 'blur'
        },
        name: {
          message: '请输入名称',
          required: true,
          trigger: 'blur'
        },
        script: [
          {
            message: '请选择脚本',
            required: true,
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    show (id) {
      if (id) {
        this.isUpdate = true
        this.form.id = id
        getAlertAutomationStrategy(id).then(res => {
          const data = res.data
          this.form = {
            id: id,
            name: data.name,
            active: data.active,
            pattern: data.pattern,
            script: data.script,
            scriptId: data.script.id
          }
        }).finally(() => {
          this.visible = true
        })
      } else {
        this.isUpdate = false
        this.visible = true
      }
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = {
            pattern: this.form.pattern,
            script_id: this.form.script.id,
            active: this.form.active,
            name: this.form.name
          }
          if (this.isUpdate) {
            updateAutomationStrategy(this.form.id, params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            createAlertAutomationStrategy(params)
              .then(res => {
                this.$message.success(res.message)
                this.$emit('ok')
                this.closeDrawer()
              })
              .finally(() => {
                this.loading = false
              })
          }
        }
      })
    },
    closeDrawer () {
      this.visible = false
      this.$refs.form.resetFields()
      this.isUpdate = false
      this.form = {
        id: '',
        name: '',
        active: false,
        pattern: '',
        script: undefined,
        scriptId: ''
      }
    },
    handleChange (v) {
      this.form.script = v
      this.form.scriptId = v.id
    }
  }
}
</script>

<style>

</style>
