<template>
  <div>
    <a-page-header
      class="page-header"
      :ghost="false"
      style="margin-bottom: 16px"
    >
      <template #title>
        <a-space size="middle">
          <a-icon type="alert"></a-icon>
          <span>自动化策略</span>
        </a-space>
      </template>
      <span>自动化策略包含名称、匹配模式、脚本等。</span>
    </a-page-header>

    <a-card :bordered="false" style="margin-bottom: 16px">
      <list-layout
        :column-default-selected="columnDefaultSelected"
        :columns-all="columnsAll"
        :get-table-data-func="getTableDataFunc"
        :table-operation-width="200"
        :refresh="refresh"
        :tools-list="['reload', 'search', 'leftTool', 'setting']"
        :defaultSelected="tableSelectData"
        @tableSelectData="(v) => tableSelectData = v"
      >
        <template #leftTool>
          <create-button
            v-permission="{action: 'ops.alert_automation_strategy.create', effect: 'disabled'}"
            @click="$refs.inputDrawer.show()"
          >
          </create-button>
        </template>
        <template #name="{ slotProps }">
          <a-tooltip v-if="slotProps.text" placement="topLeft" :title="slotProps.text">{{slotProps.text}}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template #pattern="{ slotProps }">
          <a-tooltip v-if="slotProps.text" placement="topLeft" :title="slotProps.text">{{slotProps.text}}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template #script="{ slotProps }">
          <a-tooltip v-if="slotProps.text" placement="topLeft" :title="slotProps.text.name">{{slotProps.text.name}}</a-tooltip>
          <span v-else>-</span>
        </template>
        <template #active="{ slotProps }">
          <a-switch :checked="slotProps.text"  @change="v => changeStatus(v, slotProps.record)" :loading="checkedDisabled"></a-switch>
        </template>
        <template #operation="{ slotProps }">
          <edit-button
            v-permission="{action: 'ops.alert_automation_strategy.update', effect: 'disabled'}"
            @click="$refs.inputDrawer.show(slotProps.record.id)"
          ></edit-button>
          <delete-button
            v-permission="{action: 'ops.alert_automation_strategy.delete', effect: 'disabled'}"
            @confirm="confirm(slotProps.record.id)"
          ></delete-button>
        </template>
      </list-layout>
    </a-card>

    <input-drawer ref="inputDrawer" @ok="confirm()"></input-drawer>
  </div>
</template>

<script>
import { getAlertAutomationStrategyList, deleteAutomationStrategy, updateAutomationStrategy } from '@/api/alert'
import ListLayout from '@/components/ListLayout'
import InputDrawer from './modules/InputDrawer.vue'
import DeleteButton from '@/components/button/DeleteButton'
import EditButton from '@/components/button/EditButton'
import CreateButton from '@/components/button/CreateButton'

export default {
  name: 'AlertAutomationStrategy',
  components: {
    ListLayout,
    InputDrawer,
    DeleteButton,
    EditButton,
    CreateButton
  },
  data () {
    return {
      refresh: false,
      getTableDataFunc: getAlertAutomationStrategyList,
      tableSelectData: [],
      columnsAll: [
        {
          dataIndex: 'name',
          title: '名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'pattern',
          title: '匹配模式',
          width: 160,
          ellipsis: true,
          scopedSlots: {
            customRender: 'pattern'
          }
        },
        {
          dataIndex: 'script',
          title: '脚本',
          width: 300,
          ellipsis: true,
          scopedSlots: {
            customRender: 'script'
          }
        },
        {
          dataIndex: 'active',
          title: '状态',
          width: 100,
          scopedSlots: {
            customRender: 'active'
          }
        }
      ],
      columnDefaultSelected: ['name', 'pattern', 'script', 'active'],
      checkedDisabled: false
    }
  },
  methods: {
    async confirm (id = '') {
      if (id) {
        await deleteAutomationStrategy(id).then(res => {
          this.$message.success(res.message)
        })
        this.tableSelectData.splice(
          this.tableSelectData.findIndex(item => item.key === id),
          1
        )
      }
      this.refresh = !this.refresh
    },
    changeStatus (active, item) {
      this.checkedDisabled = true
      updateAutomationStrategy(item.id, { active }).then(res => {
        this.$message.success(res.message)
        this.refresh = !this.refresh
      }).finally(() => {
        this.checkedDisabled = false
      })
    }
  }
}
</script>

<style>

</style>
